<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container bg_light_t">
          <div class="modal-header bg_darkest color_sand">
            <h5 class="modal-title" id="quote_modal_title">{{title}}</h5>
            <button type="button" class="close color_sand" @click="close()"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="col-12" v-if="processing">
              <center class="font4">
                <label>processing...</label>
              </center>
            </div>
            <div class="col-12" v-else>
              <center class="font5">
                <label v-html="text"></label>
              </center>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button-general font5" @click="close(true)" :disabled="processing">{{btn1}}</button>
            <button type="button" class="btn button-general font5" @click="close(false)" :disabled="processing">{{btn2}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: () => null
    },
    title: {
      type: String,
      required: true,
      default: () => null
    },
    text: {
      type: String,
      required: true,
      default: () => null
    },
    btn1: {
      type: String,
      required: true,
      default: () => null
    },
    btn2: {
      type: String,
      required: true,
      default: () => null
    }
  },
  data () {
    return {
      processing: false
    }
  },
  methods: {
    close (val) {
      if (typeof val !== 'undefined') {
        this.processing = true
      }
      this.$emit('close', this.id, val)
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
/*   padding: 20px 30px; */
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}

@media (min-width: 992px) {
  .modal-container {
    width: 45vw;
  }
}
@media (max-width: 992px) {
  .modal-container {
    width: 90vw;
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
</style>
